interface IMediaQueryConfig {
    query: string;
    ref: Ref<boolean | undefined>;
}

export const useMediaQuery = (mediaQueryConfigs: IMediaQueryConfig[]) => {
    const cleanUpFunctions: ReturnType<typeof subscribeToMediaQuery>[] = [];

    onBeforeMount(() => {
        mediaQueryConfigs.forEach((mediaQueryConfig) => {
            const cleanUpFunction = subscribeToMediaQuery(mediaQueryConfig.query, (event) => {
                mediaQueryConfig.ref.value = event.matches;
            });
            cleanUpFunctions.push(cleanUpFunction);
        });
    });

    onUnmounted(() => {
        // If we still get an error, explicitly check if type is function
        // if (typeof cleanUpFunction === 'function') cleanUpFunction()
        cleanUpFunctions.forEach((cleanUpFunction) => cleanUpFunction?.());
    });
};
